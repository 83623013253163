export default [{
        path: '/plansettings',
        meta: { label: 'Plan Settings' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                meta: { permission: 'show_all_plan_visit_settings'},
                name: 'plan-settings',
                component: () =>
                    import ('./../../../views/plansettings/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Plan Setting Create' , permission: 'create_plan_visit_settings' },
                name: 'CreatePlanSetting',
                component: () =>
                    import ('./../../../views/plansettings/create.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Plan Setting Details', permission: 'show_single_plan_visit_settings' },
                name: 'PlanSetting',
                component: () =>
                    import ('./../../../views/plansettings/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Plan Setting' , permission: 'edit_plan_visit_settings'},
                name: 'EditPlanSetting',
                component: () =>
                    import ('./../../../views/plansettings/edit.vue')
            },
        ]
    },
    {
        path: '/startplanusers',
        meta: { label: 'Start Plan Users' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                name: 'start-plan-users',
                meta: { permission: 'show_all_start_plan_day_users'},
                component: () =>
                    import ('./../../../views/startplanusers/index'),
            },
            {
                path: 'create',
                meta: { label: 'Start Plan User Create', permission: 'create_start_plan_day_users' },
                name: 'CreateStartPlanUser',
                component: () =>
                    import ('./../../../views/startplanusers/create'),
            },
            {
                path: ':id',
                meta: { label: 'Start Plan User Details', permission: 'show_single_start_plan_day_users' },
                name: 'StartPlanUser',
                component: () =>
                    import ('./../../../views/startplanusers/show'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Start Plan User', permission: 'edit_start_plan_day_users' },
                name: 'EditStartPlanUser',
                component: () =>
                    import ('./../../../views/startplanusers/edit')
            },
        ]
    },
    {
        path: '/disapproval-reasons',
        meta: { label: 'Disapproval Reasons' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                meta: { permission: 'show_all_disapproval_reasons'},
                name: 'disapproval-reasons',
                component: () =>
                    import ('../../../views/disapprovalreasons/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Create Disapproval Reason', permission: 'create_disapproval_reasons' },
                name: 'create-reason',
                component: () =>
                    import ('../../../views/disapprovalreasons/create.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Disapproval Reason', permission: 'edit_disapproval_reasons' },
                name: 'edit-reason',
                component: () =>
                    import ('../../../views/disapprovalreasons/edit.vue'),
            },
        ]
    },
    {
        path: '/plan_approval_day_settings',
        meta: { label: 'Plan Approval Day Settings' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                meta: { permission: 'show_all_plan_approval_day_settings'},
                name: 'plan-approval-day-settings',
                component: () =>
                    import ('./../../../views/plan_approval_day_settings/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Create Plan Approval Day Setting', permission: 'create_plan_approval_day_settings' },
                name: 'CreatePlanApprovalDaySetting',
                component: () =>
                    import ('./../../../views/plan_approval_day_settings/create.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Plan Approval Day Setting', permission: 'edit_plan_approval_day_settings' },
                name: 'EditPlanApprovalDaySetting',
                component: () =>
                    import ('./../../../views/plan_approval_day_settings/edit.vue'),
            }
        ]
    },
    {
        path: '/plan_visit_columns',
        meta: { label: 'Plan Visit Columns' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            meta: { permission: 'edit_plan_visit_columns'},
            name: 'plan-visit-settings',
            component: () =>
                import ('./../../../views/visitPlans/EditPlanVisitColumn'),
        }, ]
    },

    {
        path: '/approvalsettings',
        meta: { label: 'Approval Settings', permission: '' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                meta: { permission: 'show_all_plan_approval_settings'},
                name: 'approval-settings',
                component: () =>
                    import ('./../../../views/approval_settings/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Create Approval Setting', permission: 'create_plan_approval_settings' },
                name: 'CreateApprovalSetting',
                component: () =>
                    import ('./../../../views/approval_settings/create.vue'),
            },
            {
                path: ':id/:line/edit',
                meta: { label: 'Edit Approval Setting', permission: 'edit_plan_approval_settings' },
                name: 'EditApprovalSetting',
                component: () =>
                    import ('./../../../views/approval_settings/edit.vue')
            },

        ]
    },
]