import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
    async downloadStyledExcel(dates, items, headerItems, title, subTitle) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(title); // Create worksheet

        const colorMapping = {
            green: '008000', // Excel-compatible ARGB hex for green
            red: 'FF0000', // Excel-compatible ARGB hex for red
            blue: '0000FF', // Excel-compatible ARGB hex for blue
            black: '000000', // Excel-compatible ARGB hex for blue
            // Add more color mappings as needed
        };
        // Styles
        const headerStyle = {
            font: { bold: true, color: { argb: 'FFFFFF' }, size: 11 },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '002060' } },
            alignment: { horizontal: 'center', vertical: 'middle' },
        };

        const titleStyle = {
            font: { bold: true, color: { argb: '002060' }, size: 16 },
            alignment: { horizontal: 'center', vertical: 'middle' },
        };
        // Title in row 2, merged across D2:F2
        worksheet.mergeCells('D2:F2');
        const titleCell = worksheet.getCell('D2');
        titleCell.value = title;
        titleCell.style = titleStyle;

        const addDataWithHeaders = (data, startRow, startCol, mergedRows = 5) => {
            const headers = Object.keys(data[0]);
            headers.forEach((header, index) => {
                if (index === 0) {
                    // Merge first header across two columns
                    worksheet.mergeCells(startRow, startCol, startRow, startCol + 1);
                    const headerCell = worksheet.getCell(startRow, startCol);
                    headerCell.value = header;
                    headerCell.style = headerStyle;

                    // Merge first value cell across two columns
                    worksheet.mergeCells(startRow + 1, startCol, startRow + mergedRows, startCol + 1);
                    const valueCell = worksheet.getCell(startRow + 1, startCol);
                    valueCell.value = data[0][header];
                    valueCell.alignment = { horizontal: 'center', vertical: 'middle' };
                    valueCell.font = { bold: true };
                } else {
                    // For other headers and values, add normally
                    const headerCell = worksheet.getCell(startRow, startCol + index + 1);
                    headerCell.value = header;
                    headerCell.style = headerStyle;
                    worksheet.getColumn(startCol + index + 1).width = 10; // Set column width

                    const valueCell = worksheet.getCell(startRow + 1, startCol + index + 1);
                    valueCell.value = data[0][header];
                    valueCell.alignment = { horizontal: 'center', vertical: 'middle' };
                    valueCell.font = { bold: true };
                    worksheet.mergeCells(startRow + 1, startCol + index + 1, startRow + mergedRows, startCol + index + 1);
                }
            });
        };

        // Data1 at row 5
        addDataWithHeaders(dates, 5, 1);

        worksheet.mergeCells('A12:D12');
        const operationalTitleCell = worksheet.getCell('A12');
        operationalTitleCell.value = subTitle;
        operationalTitleCell.style = headerStyle;

        // Data3 Headers at row 14 and 15 (merged)
        // const headers3 = Object.keys(data3[0]);
        headerItems.forEach((header, index) => {
            worksheet.mergeCells(14, 1 + index, 15, 1 + index); // Merge cells for headers
            const headerCell = worksheet.getCell(14, 1 + index);
            headerCell.value = header.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()); // Convert _ to space and capitalize
            headerCell.style = headerStyle;
            worksheet.getColumn(1 + index).width = 10; // Set column width
        });

        // Data3 Values starting from row 16
        items.forEach((rowData, rowIndex) => {
            const row = worksheet.getRow(16 + rowIndex);
            row.height = 25;
            headerItems.forEach((header, colIndex) => {
                const cell = row.getCell(1 + colIndex);
                cell.value = rowData[header];
                cell.alignment = { horizontal: 'center', vertical: 'middle' };
                cell.font = { bold: true };
                if (rowData.color) {
                    this.employeeColoring(header, rowData, cell, colorMapping);
                }
                if (title == 'Overall Visits Report') {
                    this.overallVisits(header, rowData, cell);
                }
                if (title == 'Employee Tracking Report') {
                    this.employeeTracking(cell);
                }
                if (title == 'Structure Report') {
                    this.structureData(header, cell);
                }

            });
        });
        worksheet.columns.forEach(column => {
            column.width = 20; // Set a width for all columns
        });

        // Write to a file
        workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer]), title + '.xlsx'); // Download file
        });
    },
    employeeColoring(header, rowData, cell, colorMapping) {
        if (header === 'employee' || header === 'division') {
            const cellColor = colorMapping[rowData.color.toLowerCase()]; // Default to white if no color found
            cell.font = {
                bold: true,
                color: { argb: cellColor },
            };
        }
    },
    structureData(header, cell) {
        if (header === 'brick_name' || header === 'brick_id') {
            cell.font = {
                bold: true,
                color: { argb: '960000' },
            };
        }
    },
    overallVisits(header, rowData, cell) {
        if (header === 'division') {
            cell.font = {
                bold: true,
                color: { argb: '008000' },
            };
        }
        if (header === 'account' || header === 'doctor') {
            cell.font = {
                bold: true,
                color: { argb: '0000ff' },
            };
        }
        if (header === 'acc_type' || header === 'shift') {
            let shiftColor = '000000';
            if (rowData.acc_shift_id === 1) {
                shiftColor = 'EFA609';
            }
            if (rowData.acc_shift_id === 2) {
                shiftColor = 'EF09C2';
            }
            if (rowData.acc_shift_id === 3) {
                shiftColor = '09EFDE';
            }
            cell.font = {
                bold: true,
                color: { argb: shiftColor },
            };
        }
    },
    employeeTracking(cell) {

        if (cell.value && typeof cell.value === 'string') {
            const cellValue = cell.value.toLowerCase(); // Convert cell value to lowercase for comparison            // Conditional formatting based on cell value
            if (cellValue.includes('visits')) {
                cell.font = { bold: true, color: { argb: '0000FF' } }; // Blue for visits
            } else if (cellValue.includes('ow')) {
                cell.font = { bold: true, color: { argb: '00FF00' } }; // Green for ow
            } else if (cellValue.includes('off day')) {
                cell.font = { bold: true, color: { argb: 'FFA500' } }; // Orange for Off Day
            } else if (cellValue.includes('public holiday')) {
                cell.font = { bold: true, color: { argb: 'FF00FF' } }; // Magenta for Public Holiday
            } else if (cellValue.includes('vacation')) {
                cell.font = { bold: true, color: { argb: 'FF0000' } }; // Red for Vacation
            }
        }
    },

}