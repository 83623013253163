export default {
    /**
     *
     * @param {string} path
     * @param {string} fileName
     */
    exportFile(path, fileName) {
        axios
            .get(path, { responseType: "blob" })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data],{
                  type: "application/octet-stream"
                }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                this.showErrorMessage(error);
            });
    }
}
