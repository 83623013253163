export default [
    {
        path: 'vacationtypes',
        meta: { label: 'Vacation Types' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'vacation types',
            meta: {
                permission: 'show_all_vacation_types',
            },
            component: () =>
                import('./../../views/vacationtypes/index.vue'),
        },
        {
            path: 'create',
            meta: {
                label: 'Vacation Type Create',
                permission: 'create_vacation_types',
            },
            name: 'CreateVacationType',
            component: () =>
                import('./../../views/vacationtypes/create.vue'),
        },
        {
            path: ':id',
            meta: {
                label: 'Vacation Type Details',
                permission: 'show_single_vacation_types'
            },
            name: 'VacationType',
            component: () =>
                import('./../../views/vacationtypes/show.vue'),
        },
        {
            path: ':id/edit',
            meta: {
                label: 'Edit Vacation Type',
                permission: 'edit_vacation_types'
            },
            name: 'EditVacationType',
            component: () =>
                import('./../../views/vacationtypes/edit.vue')
        },
        ]
    },
    {
        path: '/vacationsettings',
        meta: { label: 'Vacation Settings' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            meta: { permission: 'show_all_vacation_settings' },
            name: 'vacation settings',
            component: () =>
                import('./../../views/vacationsettings/index.vue'),
        },
        {
            path: 'create',
            meta: { label: 'Vacation Setting Create', permission: 'create_vacation_settings' },
            name: 'CreateVacationSetting',
            component: () =>
                import('./../../views/vacationsettings/create.vue'),
        },
        {
            path: ':id',
            meta: { label: 'Vacation Setting Details', permission: 'show_single_vacation_settings' },
            name: 'VacationSetting',
            component: () =>
                import('./../../views/vacationsettings/show.vue'),
        },
        {
            path: ':id/edit',
            meta: { label: 'Edit Vacation Setting', permission: 'edit_vacation_settings' },
            name: 'EditVacationSetting',
            component: () =>
                import('./../../views/vacationsettings/edit.vue')
        },
        ]
    },
    {
        path: '/vacation-approvals',
        meta: { label: 'Vacation Approvals' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'vacation approvals',
            meta: {
                permission: 'show_all_vacation_approvals'
            },
            component: () =>
                import('../../views/vacationapprovals/VacationApprovals.vue'),
        },
        ]
    },

    {
        path: '/commercial-approvals',
        meta: { label: 'Commercial Approvals' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'commercial approvals',
            meta: {
                permission: 'show_all_commercial_approvals'
            },
            component: () =>
                import('../../views/commercialapprovals/CommercialApprovals.vue'),
        },
        {
            path: ':id/:required',
            meta: { label: 'Commercial Request Details', permission: 'show_single_commercial_requests' },
            name: 'CommercialWithApproval',
            component: () =>
                import('./../../views/commercialrequest/showWithApproval.vue'),
        },
        ]
    },

    {
        path: '/expense-approvals',
        meta: { label: 'Expense Approvals' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'expense approvals',
            meta: {
                permission: 'show_all_expense_approvals'
            },
            component: () =>
                import('../../views/expenseapprovals/ExpenseApprovals.vue'),
        },
        ]
    },
    {
        path: '/active-inactive-accounts',
        meta: { label: 'Active & Inactive Accounts' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'active inactive accounts',
            meta: {
                permission: 'show_all_active_inactive_accounts'
            },
            component: () =>
                import('../../views/activeinactiveaccounts/activeInactiveAccounts.vue'),
        },
        ]
    },
    {
        path: '/active-inactive-approvals',
        meta: { label: 'Active & Inactive Approvals' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'active-inactive-approvals',
            meta: {
                permission: ''
            },
            component: () =>
                import('../../views/activeinactiveapproval/ActiveInactiveApprovals.vue'),
        },
        ]
    },

    {
        path: '/favourite-list-approvals',
        meta: { label: 'Favourite List Approvals' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'favourite-list-approvals',
            meta: {
                permission: ''
            },
            component: () =>
                import('../../views/FavouriteListApprovals/FavouriteListApprovals.vue'),
        },
        ]
    },

    {
        path: '/officeworktypes',
        meta: { label: 'Office Work Types' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'officework-types',
            meta: {
                permission: 'show_all_office_work_types'
            },
            component: () =>
                import('../../views/officeworktypes/index.vue'),
        },
        {
            path: 'create',
            meta: {
                label: 'Office Work Type Create',
                permission: 'create_office_work_types'
            },
            name: 'CreateOfficeWorkType',
            component: () =>
                import('../../views/officeworktypes/create.vue'),
        },
        {
            path: ':id',
            meta: {
                label: 'Office Work Type Details',
                permission: 'show_single_office_work_types'
            },
            name: 'OfficeWorkType',
            component: () =>
                import('../../views/officeworktypes/show.vue'),
        },
        {
            path: ':id/edit',
            meta: {
                label: 'Edit Office Work Type',
                permission: 'edit_office_work_types'
            },
            name: 'EditOfficeWorkType',
            component: () =>
                import('../../views/officeworktypes/edit.vue')
        },
        ]
    },

    {
        path: '/publicholidays',
        meta: { label: 'Public Holidays' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'public-holidays',
            meta: {
                permission: 'show_all_public_holidays'
            },
            component: () =>
                import('./../../views/publicholidays/index.vue'),
        },
        {
            path: 'create',
            meta: {
                label: 'Public Holiday Create',
                permission: 'create_public_holidays'
            },
            name: 'CreatePublicHoliday',
            component: () =>
                import('./../../views/publicholidays/create.vue'),
        },
        {
            path: ':id',
            meta: {
                label: 'Public Holiday Details',
                permission: 'show_single_public_holidays'
            },
            name: 'PublicHoliday',
            component: () =>
                import('./../../views/publicholidays/show.vue'),
        },
        {
            path: ':id/edit',
            meta: {
                label: 'Edit Public Holiday',
                permission: 'edit_public_holidays'
            },
            name: 'EditPublicHoliday',
            component: () =>
                import('./../../views/publicholidays/edit.vue')
        },
        ]
    },

    {
        path: '/personal-request-types',
        meta: { label: 'Personal Request Types' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'personal-request-types',
            meta: {
                permission: 'show_all_personal_request_types'
            },
            component: () =>
                import('../../views/personalrequesttypes/index.vue'),
        },
        {
            path: 'create',
            meta: {
                label: 'Personal Request Type Create',
                permission: 'create_personal_request_types'
            },
            name: 'create-personal-request-type',
            component: () =>
                import('../../views/personalrequesttypes/create.vue'),
        },
        {
            path: ':id',
            meta: {
                label: 'Personal Request Type Details',
                permission: 'show_single_personal_request_types'
            },
            name: 'personal-request-type',
            component: () =>
                import('../../views/personalrequesttypes/show.vue'),
        },
        {
            path: ':id/edit',
            meta: {
                label: 'Edit Personal Request Type',
                permission: 'edit_personal_request_types'
            },
            name: 'edit-personal-request-type',
            component: () =>
                import('../../views/personalrequesttypes/edit.vue')
        },
        ]
    },

    {
        path: '/costs',
        meta: { label: 'Commercial Cost Types' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'costs',
            meta: {
                permission: 'show_all_cost_types'
            },
            component: () =>
                import('../../views/commercialcosts/index.vue'),
        },
        {
            path: 'create',
            meta: {
                label: 'Commercial Cost Type Create',
                permission: 'create_cost_types'
            },
            name: 'CreateCostType',
            component: () =>
                import('../../views/commercialcosts/create.vue'),
        },
        {
            path: ':id',
            meta: {
                label: 'Commercial Cost Type Details',
                permission: 'show_single_cost_types'
            },
            name: 'CostType',
            component: () =>
                import('../../views/commercialcosts/show.vue'),
        },
        {
            path: ':id/edit',
            meta: {
                label: 'Edit Commercial Cost Type',
                permission: 'edit_cost_types'
            },
            name: 'EditCostType',
            component: () =>
                import('../../views/commercialcosts/edit.vue')
        },
        ]
    },

    {
        path: '/request-types',
        meta: { label: 'Request Types' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'request-types',
            meta: {
                permission: 'show_all_request_types'
            },
            component: () =>
                import('../../views/commercialrequesttypes/index.vue'),
        },
        {
            path: 'create',
            meta: {
                label: 'Request Type Create',
                permission: 'create_request_types'
            },
            name: 'CreateRequestType',
            component: () =>
                import('../../views/commercialrequesttypes/create.vue'),
        },
        {
            path: ':id',
            meta: {
                label: 'Request Type Details',
                permission: 'show_single_request_types'
            },
            name: 'RequestType',
            component: () =>
                import('../../views/commercialrequesttypes/show.vue'),
        },
        {
            path: ':id/edit',
            meta: {
                label: 'Edit Request Type',
                permission: 'edit_request_types'
            },
            name: 'EditRequestType',
            component: () =>
                import('../../views/commercialrequesttypes/edit.vue')
        },
        ]
    },
    {
        path: '/expense-types',
        meta: { label: 'Expense Types' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'expense-types',
                meta: { permission: 'show_all_expense_types' },
                component: () => import('../../views/expensetypes/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Expense Type Create', permission: 'create_expense_types' },
                name: 'CreateExpenseType',
                component: () => import('../../views/expensetypes/create.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Expense Type Details', permission: 'show_single_expense_types' },
                name: 'ExpenseType',
                component: () => import('../../views/expensetypes/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Expense Type', permission: 'edit_expense_types' },
                name: 'EditExpenseType',
                component: () => import('../../views/expensetypes/edit.vue')
            },
        ]
    },
    {
        path: '/expense-meals',
        meta: { label: 'Expense Meals' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'expense-meals',
                meta: { permission: 'show_all_expense_meals' },
                component: () => import('../../views/expensemeals/index.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Expense Meal Meals', permission: 'show_single_expense_meals' },
                name: 'ExpenseMeal',
                component: () => import('../../views/expensemeals/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Expense Meal', permission: 'edit_expense_meals' },
                name: 'EditExpenseMeal',
                component: () => import('../../views/expensemeals/edit.vue')
            },
        ]
    },
    {
        path: '/expense-location-settings',
        meta: { label: 'Expense Location Settings' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'expense-location-settings',
                meta: { permission: 'show_all_expense_location_settings' },
                component: () => import('../../views/ExpenseLocationSetting/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Expense Location Setting Create', permission: 'create_expense_location_settings' },
                name: 'CreateExpenseLocationSetting',
                component: () => import('../../views/ExpenseLocationSetting/create.vue'),
            },
            {

                path: ':id',
                meta: { label: 'Expense Location Setting Details', permission: 'show_single_expense_location_settings' },
                name: 'ExpenseLocationSetting',
                component: () => import('../../views/ExpenseLocationSetting/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Expense Location Setting', permission: 'edit_expense_location_settings' },
                name: 'EditExpenseLocationSetting',
                component: () => import('../../views/ExpenseLocationSetting/edit.vue')
            },
        ]
    },
    {
        path: '/expense-location-prices',
        meta: { label: 'Expense Location Prices' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'expense-location-prices',
                meta: { permission: 'show_all_expense_location_prices' },
                component: () => import('../../views/expenseLocationPrice/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Expense Location Price Create', permission: 'create_expense_location_prices' },
                name: 'CreateExpenseLocationPrice',
                component: () => import('../../views/expenseLocationPrice/create.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Expense Location Price', permission: 'edit_expense_location_prices' },
                name: 'EditExpenseLocationPrice',
                component: () => import('../../views/expenseLocationPrice/edit.vue')
            },
        ]
    },
    {
        path: '/expense-price-factors',
        meta: { label: 'Expense Prices Factors' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'expense-price-factors',
                meta: { permission: 'show_all_expense_price_factors' },
                component: () => import('../../views/expensePriceFactors/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Expense Price Factor Create', permission: 'create_expense_price_factors' },
                name: 'CreateExpensePriceFactor',
                component: () => import('../../views/expensePriceFactors/create.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Expense Price Factor Details', permission: 'show_single_expense_price_factors' },
                name: 'ExpensePriceFactor',
                component: () => import('../../views/expensePriceFactors/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Expense Price Factor', permission: 'edit_expense_price_factors' },
                name: 'EditExpensePriceFactor',
                component: () => import('../../views/expensePriceFactors/edit.vue')
            },
        ]
    },
    {
        path: '/kilometers-average',
        meta: { label: 'Kilometers Average' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'kilometers-average',
                meta: { permission: 'show_all_kilometers_average' },
                component: () => import('../../views/kilometersAverage/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Expense Kilometers Average Create', permission: 'create_kilometers_average' },
                name: 'CreateKilometersAverage',
                component: () => import('../../views/kilometersAverage/create.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Expense Kilometers Average Details', permission: 'show_single_kilometers_average' },
                name: 'KilometersAverage',
                component: () => import('../../views/kilometersAverage/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Expense Kilometers Average', permission: 'edit_kilometers_average' },
                name: 'EditKilometersAverage',
                component: () => import('../../views/kilometersAverage/edit.vue')
            },
        ]
    },
    {
        path: '/expense-settings',
        meta: { label: 'Expense Settings' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'expense-settings',
                meta: { permission: 'show_all_expense_settings' },
                component: () => import('../../views/expenseSettings/index.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Expense Expense Settings', permission: 'edit_expense_settings' },
                name: 'EditExpenseSettings',
                component: () => import('../../views/expenseSettings/edit.vue')
            },
        ]
    },
    {
        path: '/promotional-material-types',
        meta: { label: 'Promotional Material Types' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'promotional-material-types',
                meta: { permission: 'show_all_promotional_material_types' },
                component: () => import('../../views/promotionaltypes/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Promotional Material Type Create', permission: 'create_promotional_material_types' },
                name: 'create-promotional-material-type',
                component: () => import('../../views/promotionaltypes/create.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Promotional Material Type Details', permission: 'show_single_promotional_material_types' },
                name: 'show-promotional-material-type',
                component: () => import('../../views/promotionaltypes/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Promotional Material Type', permission: 'edit_promotional_material_types' },
                name: 'edit-promotional-material-type',
                component: () => import('../../views/promotionaltypes/edit.vue')
            },
        ]
    },

    {
        path: '/scientific-offices',
        meta: { label: 'Scientific Offices' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'scientific-offices',
                meta: { permission: 'show_all_scientific_offices' },
                component: () => import('../../views/scientificoffices/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Scientific Office Create', permission: 'create_scientific_offices' },
                name: 'create-scientific-office',
                component: () => import('../../views/scientificoffices/create.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Scientific Office Details', permission: 'show_single_scientific_offices' },
                name: 'show-scientific-office',
                component: () => import('../../views/scientificoffices/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Scientific Office', permission: 'edit_scientific_offices' },
                name: 'edit-scientific-office',
                component: () => import('../../views/scientificoffices/edit.vue')
            },
        ]
    },
    {
        path: '/material-vendors',
        meta: { label: 'Vendors' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'material_vendors',
                meta: { permission: 'show_all_material_vendors' },
                component: () => import('../../views/materialVendors/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Vendor Create', permission: 'create_material_vendors' },
                name: 'create-material-vendor',
                component: () => import('../../views/materialVendors/create.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Vendor Details', permission: 'show_single_material_vendors' },
                name: 'show-material-vendor',
                component: () => import('../../views/materialVendors/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Vendor', permission: 'edit_material_vendors' },
                name: 'edit-material-vendor',
                component: () => import('../../views/materialVendors/edit.vue')
            },
        ]
    },
    {
        path: '/commercial-pharmacies',
        meta: { label: 'Commercial Pharmacies Settings' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'commercial-pharmacies',
                meta: { permission: 'show_all_commercial_pharmacies' },
                component: () => import('../../views/commercialpharmaciessetting/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Commercial Pharmacies Create', permission: 'create_commercial_pharmacies' },
                name: 'create-commercial-pharmacies',
                component: () => import('../../views/commercialpharmaciessetting/create.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Commercial Pharmacies Details', permission: 'show_single_commercial_pharmacies' },
                name: 'show-commercial-pharmacies',
                component: () => import('../../views/commercialpharmaciessetting/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Commercial Pharmacies', permission: 'edit_commercial_pharmacies' },
                name: 'edit-commercial-pharmacies',
                component: () => import('../../views/commercialpharmaciessetting/edit.vue')
            },
        ]
    },
    {
        path: '/paymentMethods',
        meta: { label: 'PaymentMethods' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'paymentMethods',
                meta: { permission: 'show_all_payment_methods' },
                component: () => import('../../views/paymentMethods/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Payment Method Create', permission: 'create_payment_methods' },
                name: 'create-payment-method',
                component: () => import('../../views/paymentMethods/create.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Payment Method Details', permission: 'show_single_payment_methods' },
                name: 'show-payment-method',
                component: () => import('../../views/paymentMethods/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Payment Method', permission: 'edit_payment_methods' },
                name: 'edit-payment-method',
                component: () => import('../../views/paymentMethods/edit.vue')
            },
        ]
    },
    {
        path: '/categories',
        meta: { label: 'Categories' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'categories',
                meta: { permission: 'show_all_categories' },
                component: () => import('../../views/categories/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Categories Create', permission: 'create_categories' },
                name: 'create-categories',
                component: () => import('../../views/categories/create.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Category Details', permission: 'show_single_category' },
                name: 'show-category',
                component: () => import('../../views/categories/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Category', permission: 'edit_category' },
                name: 'edit-category',
                component: () => import('../../views/categories/edit.vue')
            },
        ]
    },
    {
        path: '/categories-types',
        meta: { label: 'Category Types' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'category-types',
                meta: { permission: 'show_all_category_types' },
                component: () => import('../../views/categorytypes/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Category Types Create', permission: 'create_category_types' },
                name: 'create-category-types',
                component: () => import('../../views/categorytypes/create.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Category Type Details', permission: 'show_single_category_types' },
                name: 'show-category-type',
                component: () => import('../../views/categorytypes/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Category Type', permission: 'edit_category_types' },
                name: 'edit-category-type',
                component: () => import('../../views/categorytypes/edit.vue')
            },
        ]
    },
    {
        path: '/custody',
        meta: { label: 'Custody' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'custody',
                meta: { permission: 'show_all_commercial_custodies' },
                component: () => import('../../views/custody/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Custody Create', permission: 'create_commercial_custodies' },
                name: 'create-custody',
                component: () => import('../../views/custody/create.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Custody Details', permission: 'show_single_commercial_custodies' },
                name: 'show-custody',
                component: () => import('../../views/custody/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Custody', permission: 'edit_commercial_custodies' },
                name: 'edit-custody',
                component: () => import('../../views/custody/edit.vue')
            },
        ]
    },

    {
        path: 'commercial-tabs',
        meta: { label: 'Commercial Tabs ', permission: '' },
        name: 'commercial-tabs',
        component: () => import('./../../views/commercialrequest/showTabs.vue'),
    },
]
