export default [
    
    {
        path: '/sales-settings',
        meta: { label: 'Sales Settings' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                name: 'sales-settings',
                meta:{
                    permission: 'show_all_sales_settings'
                },
                component: () =>
                    import ('./../../views/salessettings/index.vue'),
            },
            {
                path: 'create',
                meta: { 
                    label: 'Create Sales Settings',
                    permission:'create_sales_setting'
                },
                name: 'CreateSalesSettings',
                component: () =>
                    import ('./../../views/salessettings/create.vue'),
            },
            {
                path: ':id/edit',
                meta: { 
                    label: 'Edit Sales Settings',
                    permission:'edit_sales_setting' 
                },
                name: 'EditSalesSettings',
                component: () =>
                    import ('./../../views/salessettings/edit.vue')
            },
            {
                path: ':id',
                meta: { 
                    label: 'Show Sales Settings',
                    permission:'show_single_sales_setting' 
                },
                name: 'ShowSalesSettings',
                component: () =>
                    import ('./../../views/salessettings/show.vue')
            },
        ]
    },
    
    {
        path: '/sales-mappings',
        meta: { label: 'Sales Mapping' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                name: 'sales-mapping',
                meta:{
                    permission:'show_all_sales_mappings'
                },
                component: () =>
                    import ('./../../views/salesmappings/index'),
            },
            {
                path: 'create',
                meta: { 
                    label: 'Sales Mapping Create',
                    permission:'create_sales_mapping' 
                },
                name: 'CreateSalesMapping',
                component: () =>
                    import ('./../../views/salesmappings/create'),
            },
            {
                path: ':id',
                meta: { 
                    label: 'Sales Mapping Details',
                    permission:'show_single_sales_mapping' 
                },
                name: 'SalesMapping',
                component: () =>
                    import ('./../../views/salesmappings/show'),
            },
            {
                path: ':id/edit',
                meta: { 
                    label: 'Edit Sales Mapping',
                    permission:'edit_sales_mapping' 
                },
                name: 'EditSalesMapping',
                component: () =>
                    import ('./../../views/salesmappings/edit')
            },
        ]
    },

    {
        path: '/sales',
        meta: { label: 'Sales' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                name: 'sales',
                meta: { permission:'show_all_sales'},
                component: () =>
                    import ('./../../views/sales/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Sales  Create',permission:'create_sales' },
                name: 'CreateSales',
                component: () =>
                    import ('./../../views/sales/create.vue'),
            },
            {
                path: 'delete',
                meta: { 
                    label: 'Delete Sales Details',
                    permission:'delete_sales_details'
                },
                name: 'DeleteSalesDetails',
                component: () =>
                    import ('./../../views/sales/deleteSalesDetails.vue'),
            },
            // {
            //     path: ':id',
            //     meta: { label: 'Sales Details' },
            //     name: 'SalesMapping',
            //     component: () =>
            //         import ('./../../views/sales/show.vue'),
            // },
            {
                path: ':id/edit',
                meta: { label: 'Edit Sales',permission:'show_single_sales' },
                name: 'EditSales',
                component: () =>
                    import ('./../../views/sales/edit.vue')
            },
            {
                path: '/upload',
                meta: { label: 'Upload',permission:'import_sales' },
                name: 'UploadSales',
                component: () =>
                    import ('./../../views/sales/upload.vue'),
            }
        ]
    },

    {
        path: '/unified-code',
        meta: { label: 'Mapping Unified Code' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                name: 'unified-codes',
                meta:{
                    permission:'show_all_unified_codes'
                },
                component: () =>
                    import ('./../../views/mappingunifiedcode/index.vue'),
            },
            {
                path: 'create',
                meta: { 
                    label: 'Create Mapping Unified Code',
                    permission:'create_unified_code'
                },
                name: 'CreateMappingUnifiedCode',
                component: () =>
                    import ('./../../views/mappingunifiedcode/create.vue'),
            },
            {
                path: ':id',
                meta: { 
                    label: 'Mapping Unified Code Details',
                    permission:'show_single_unified_code'
                },
                name: 'MappingUnifiedCode',
                component: () =>
                    import ('./../../views/mappingunifiedcode/show.vue'),
            },
            {
                path: ':id/edit',
                meta: { 
                    label: 'Edit Mapping Unified Code',
                    permission:'edit_unified_code' 
                },
                name: 'EditMappingUnifiedCode',
                component: () =>
                    import ('./../../views/mappingunifiedcode/edit.vue')
            },
        ]
    },

    {
        path: '/contribution',
        meta: { label: 'Contribution' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                name: 'contribution',
                meta:{
                    permission:'show_all_contributions'
                },
                component: () =>
                    import ('./../../views/contribution/index.vue'),
            },
            {
                path: 'create',
                meta: { 
                    label: 'Create Contribution',
                    permission:'create_contribution'
                },
                name: 'CreateContribution',
                component: () =>
                    import ('./../../views/contribution/create.vue'),
            },
            // {
            //     path: ':id/edit',
            //     meta: { label: 'Edit Contribution' },
            //     name: 'EditContribution',
            //     component: () =>
            //         import ('./../../views/contribution/edit.vue')
            // },
        ]
    },

    {
        path: '/target-details',
        meta: { label: 'Target Details' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                name: 'target-details',
                meta:{
                    permission:'show_all_target_details'
                },
                component: () =>
                    import ('./../../views/targetdetails/index.vue'),
            },
            {
                path: 'create',
                meta: { 
                    label: 'Create Target Details',
                    permission:'create_target_details'
                },
                name: 'CreateTargetDetails',
                component: () =>
                    import ('./../../views/targetdetails/create.vue'),
            },
            {
                path: 'delete',
                meta: { 
                    label: 'Delete Target Details',
                    permission:'delete_target_details'
                },
                name: 'DeleteTargetDetails',
                component: () =>
                    import ('./../../views/targetdetails/deleteTargetDetails.vue'),
            },
            // {
            //     path: ':id/edit',
            //     meta: { label: 'Edit Contribution' },
            //     name: 'EditContribution',
            //     component: () =>
            //         import ('./../../views/contribution/edit.vue')
            // },
        ]
    },

    {
        path: '/target',
        meta: { label: 'Target' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                name: 'target',
                meta:{
                    permission:'show_all_targets'
                },
                component: () =>
                    import ('./../../views/target/index.vue'),
            },
            {
                path: 'create',
                meta: { 
                    label: 'Create Target',
                    permission:'create_target'
                },
                name: 'CreateTarget',
                component: () =>
                    import ('./../../views/target/create.vue'),
            },
            // {
            //     path: ':id/edit',
            //     meta: { label: 'Edit Target' },
            //     name: 'EditTarget',
            //     component: () =>
            //         import ('./../../views/target/edit.vue')
            // },
        ]
    },

]