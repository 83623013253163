export default [
  {
    path: '/shifts',
    meta: { label: 'Shifts' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_shifts' },
        name: 'shifts',
        component: () => import('./../../../views/shifts/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Shift Create', permission: 'create_shifts' },
        name: 'CreateShift',
        component: () => import('./../../../views/shifts/create'),
      },
      {
        path: ':id',
        meta: { label: 'Shift Details', permission: 'show_single_shifts' },
        name: 'Shift',
        component: () => import('./../../../views/shifts/show'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Shift', permission: 'edit_shifts' },
        name: 'EditShift',
        component: () => import('./../../../views/shifts/edit')
      },
    ]
  },
  {
    path: '/accounttypes',
    meta: { label: 'AccountTypes' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_account_types' },
        name: 'account-types',
        component: () => import('./../../../views/accounttypes/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Account Type Create', permission: 'create_account_types' },
        name: 'CreateAccountType',
        component: () => import('./../../../views/accounttypes/create'),
      },
      {
        path: ':id',
        meta: { label: 'AccountType Details', permission: 'show_single_account_types' },
        name: 'AccountType',
        component: () => import('./../../../views/accounttypes/show'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit AccountType', permission: 'edit_account_types' },
        name: 'EditAccountType',
        component: () => import('./../../../views/accounttypes/edit')
      },
    ]
  },
  {
    path: '/classes',
    meta: { label: 'Classes' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_classes' },
        name: 'classes',
        component: () => import('./../../../views/classes/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Classes Create', permission: 'create_classes' },
        name: 'CreateClasses',
        component: () => import('./../../../views/classes/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Classes Details', permission: 'show_single_classes' },
        name: 'Classes',
        component: () => import('./../../../views/classes/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Classes', permission: 'edit_classes' },
        name: 'EditClasses',
        component: () => import('./../../../views/classes/edit.vue')
      },
    ]
  },
  {
    path: '/bricks',
    meta: { label: 'Bricks' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_bricks' },
        name: 'bricks',
        component: () => import('./../../../views/bricks/index.vue'),
      },
      {
        path: '/mapping-positions',
        meta: { permission: 'show_all_bricks' },
        name: 'bricks mapping',
        component: () => import('./../../../views/bricks/brick-mapping-positions.vue'),
      },
      {
        path: '/shared-bricks',
        meta: { permission: 'show_all_bricks' },
        name: 'shared bricks',
        component: () => import('./../../../views/bricks/shared-bricks.vue'),
      },
      {
        path: '/copy-accounts-bricks',
        meta: { permission: 'show_all_bricks' },
        name: 'copy-accounts-bricks',
        component: () => import('./../../../views/bricks/copy-accounts.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Brick Create', permission: 'create_bricks' },
        name: 'CreateBrick',
        component: () => import('./../../../views/bricks/create'),
      },
      {
        path: ':id',
        meta: { label: 'Brick Details', permission: 'show_single_bricks' },
        name: 'Brick',
        component: () => import('./../../../views/bricks/show'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Brick', permission: 'edit_bricks' },
        name: 'EditBrick',
        component: () => import('./../../../views/bricks/edit')
      },
    ]
  },
  {
    path: '/specialities',
    meta: { label: 'Specialities' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_specialities' },
        name: 'specialities',
        component: () => import('./../../../views/specialities/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Speciality Create', permission: 'create_specialities' },
        name: 'CreateSpeciality',
        component: () => import('./../../../views/specialities/create'),
      },
      {
        path: ':id',
        meta: { label: 'Speciality Details', permission: 'show_single_specialities' },
        name: 'Speciality',
        component: () => import('./../../../views/specialities/show'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Speciality', permission: 'edit_specialities' },
        name: 'EditSpeciality',
        component: () => import('./../../../views/specialities/edit')
      },
    ]
  },

  {
    path: '/levels',
    meta: { label: 'Levels' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_levels' },
        name: 'levels',
        component: () => import('./../../../views/levels/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Level Create', permission: 'create_levels' },
        name: 'CreateLevel',
        component: () => import('./../../../views/levels/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Level Details', permission: 'show_single_levels' },
        name: 'Level',
        component: () => import('./../../../views/levels/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Level', permission: 'edit_levels' },
        name: 'EditLevel',
        component: () => import('./../../../views/levels/edit')
      },
    ]
  },
  {
    path: '/personalitytypes',
    meta: { label: 'Personality Types' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'personality-types',
        meta: { permission: 'show_all_personalitytypes' },
        component: () => import('./../../../views/personalitytypes/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Personality Type Create', permission: 'create_personalitytypes' },
        name: 'CreatePersonalityType',
        component: () => import('./../../../views/personalitytypes/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Personality Type Details', permission: 'show_single_personalitytypes' },
        name: 'PersonalityType',
        component: () => import('./../../../views/personalitytypes/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Personality Type', permission: 'edit_personalitytypes' },
        name: 'EditPersonalityType',
        component: () => import('./../../../views/personalitytypes/edit.vue')
      },
    ]
  },
  {
    path: '/account-classifications',
    meta: { label: 'Classifications' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'account-classifications',
        meta: { permission: 'show_all_account_classifications' },
        component: () => import('./../../../views/accountclassification/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Classification Create', permission: 'create_account_classifications' },
        name: 'create-account-classification',
        component: () => import('./../../../views/accountclassification/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Classification Details', permission: 'show_single_account_classifications' },
        name: 'show-account-classification',
        component: () => import('./../../../views/accountclassification/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Classification', permission: 'edit_account_classifications' },
        name: 'edit-account-classification',
        component: () => import('./../../../views/accountclassification/edit.vue')
      },
    ]
  },
  {
    path: '/doctors',
    meta: { label: 'Doctors' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'doctors',
        meta: { permission: 'show_all_doctors' },
        component: () => import('./../../../views/doctors/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Doctor Create', permission: 'create_doctors' },
        name: 'CreateDoctor',
        component: () => import('./../../../views/doctors/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Doctor Details', permission: 'show_single_doctors' },
        name: 'Doctor',
        component: () => import('./../../../views/doctors/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Doctor', permission: 'edit_doctors' },
        name: 'EditDoctor',
        component: () => import('./../../../views/doctors/edit.vue')
      },
    ]
  },
  {
    path: '/accounts',
    meta: { label: 'Accounts' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_accounts' },
        name: 'accounts',
        component: () => import('./../../../views/accounts/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Account Create', permission: 'create_accounts' },
        name: 'CreateAccount',
        component: () => import('./../../../views/accounts/create2.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Account Details', permission: 'show_single_accounts' },
        name: 'Account',
        component: () => import('./../../../views/accounts/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Account', permission: 'edit_accounts' },
        name: 'EditAccount',
        component: () => import('./../../../views/accounts/edit2.vue')
      },
      {
        path: '/merge-accounts',
        meta: { permission: 'edit_accounts' },
        name: 'merge-accounts',
        component: () => import('./../../../views/accounts/mergeAccounts.vue'),
      },
    ]
  },

  {
    path: '/list-types',
    meta: { label: 'List Types' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_list_type_settings' },
        name: 'list-type',
        component: () => import('./../../../views/listtypesettings/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'List Type Create', permission: 'create_list_type_settings' },
        name: 'CreateListType',
        component: () => import('./../../../views/listtypesettings/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'ListType Details', permission: 'show_single_list_type_settings' },
        name: 'ListType',
        component: () => import('./../../../views/listtypesettings/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit ListType', permission: 'edit_list_type_settings' },
        name: 'EditListType',
        component: () => import('./../../../views/listtypesettings/edit.vue')
      },
    ]
  },
  {
    path: '/favourite-lists',
    meta: { label: 'Favourite List' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: '' },
        name: 'favourite-list',
        component: () => import('./../../../views/favouritelist/create.vue'),
      },
    ]
  },
  {
    path: '/kol-lists',
    meta: { label: 'Kol List' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: '' },
        name: 'kol-list',
        component: () => import('./../../../views/kolList/create.vue'),
      },
    ]
  },
  {
    path: '/profiling',
    meta: { label: 'Profiling' },
    component: {
      render(c) { return c('router-view') }
    },

    children: [
      {
        path: '',
        meta: { permission: '' },
        name: 'profiling',
        component: () => import('./../../../views/profiling/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Create', permission: 'create_list_type_settings' },
        name: 'CreateProfiling',
        component: () => import('./../../../views/profiling/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Profiling Details', permission: 'show_profiling' },
        name: 'ShowProfiling',
        component: () => import('./../../../views/profiling/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Profiling', permission: 'edit_profiling' },
        name: 'EditProfiling',
        component: () => import('./../../../views/profiling/edit.vue')
      },
    ]
  },
  {
    path: '/doctor-profiling',
    meta: { label: 'Doctor Profiling' },
    component: {
      render(c) { return c('router-view') }
    },

    children: [
      {
        path: '',
        meta: { permission: '' },
        name: 'DoctorProfiling',
        component: () => import('./../../../views/DoctorProfiling/create.vue'),
      },

    ]
  },
  {
    path: '/list-management',
    meta: { label: 'List Management' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: '' },
        name: 'ListManagement',
        component: () => import('./../../../views/listmanagement/index.vue'),
      },

    ]
  }
]
