export default [
    {
        path: 'mapping-report',
        meta: { label: 'Mapping Report', permission: 'show_reports_mapping' },
        name: 'mapping-report',
        component: () => import('./../../views/reports/MappingReport.vue'),
    },
    {
        path: 'error-mapping-report',
        meta: { label: 'Error Mapping Report', permission: 'show_error_reports_mapping' },
        name: 'error-mapping-report',
        component: () => import('./../../views/reports/ErrorMappingReport.vue'),
    },
    {
        path: 'product-mapping-report',
        meta: { label: 'Product Mapping Report', permission: 'show_reports_product_mapping' },
        name: 'product-mapping-report',
        component: () => import('./../../views/reports/ProductMappingReport.vue'),
    },
    {
        path: 'target-details',
        meta: { label: 'Target Details Report', permission: 'show_reports_target_details' },
        name: 'target-details-report',
        component: () => import('./../../views/reports/TargetDetailsReport.vue'),
    },
    {
        path: 'sales-details',
        meta: { label: 'Sales Details Report', permission: 'show_reports_sales_details' },
        name: 'sales-details',
        component: () => import('./../../views/reports/SalesDetailsReport.vue'),
    },
    {
        path: 'sales-summery',
        meta: { label: 'Sales Summery Report', permission: 'show_reports_sales_summery' },
        name: 'sales-summery',
        component: () => import('./../../views/reports/SalesSummeryReport.vue'),
    },
    {
        path: 'sales-per-distributor',
        meta: { label: 'Sales Per Distributor Report', permission: 'show_reports_sales_per_distributor' },
        name: 'sales-per-distributors',
        component: () => import('./../../views/reports/SalesPerDistributorReport.vue'),
    },
    {
        path: 'sales-employees',
        meta: { label: 'Sales Employees Report', permission: 'show_reports_sales_employees' },
        name: 'sales-employees',
        component: () => import('./../../views/reports/SalesEmployeesReport.vue'),
    },
    {
        path: 'sales-achievement',
        meta: { label: 'Sales Achievement Report', permission: 'show_reports_sales_achievement' },
        name: 'sales-achievement',
        component: () => import('./../../views/reports/SalesAchievementReport.vue'),
    },
    // {
    //     path: 'sales-achievement',
    //     meta: { label: 'Sales Achievement Report', permission: 'show_reports_sales_achievement' },
    //     name: 'sales-achievement',
    //     component: () => import('./../../views/reports/SalesAchievementReport.vue'),
    // },
    {
        path: 'target-summary',
        meta: { label: 'Target Summary Report', permission: 'show_reports_target_summary' },
        name: 'target-summary',
        component: () => import('./../../views/reports/TargetSummaryReport.vue'),
    },
    {
        path: 'sales-summary-unified-report',
        meta: { label: 'Sales Summary Unified Report', permission: 'show_reports_sales_summary_unified' },
        name: 'sales-summary-unified-report',
        component: () => import('./../../views/reports/SalesSummaryUnifiedReport.vue'),
    },
    
]
